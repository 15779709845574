import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useLocation, useParams, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { ThemeContext } from "../context/ThemeContext";
import styled from "styled-components";
import { ORDER_STATUS_TYPES } from "../config/OrderStatusTypes";
import { BRANDS } from "../config/brands";

import Title from "../components/Title";
import Description from "../components/Description";

import * as platformApi from "../handlers/platform-api";
import ErrorDetails from "../components/ErrorDetails";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Spinner = styled.div`
  margin: 200px auto 95px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  p {
    text-align: center;
  }
`;

const Loading = (props) => {
  const { t } = useTranslation();
  const { value: appState, setValue: setAppState } = useContext(AppContext);
  const { setValue: setThemeState } = useContext(ThemeContext);
  const location = useLocation();
  const pathParams = useParams();
  const queryParams = new URLSearchParams(location.search);
  const id = pathParams ? pathParams.id : appState?.order?.orderNumber;
  const key = queryParams.get("orderKey") || appState?.order?.orderKey;
  const brand = queryParams.get("b") || appState?.order?.b;
  const redirectTo = useRef("");

  sessionStorage.setItem("orderId", id);
  sessionStorage.setItem("orderKey", key);
  sessionStorage.setItem("brand", brand);

  const [pending, setPending] = useState(true);
  const [found, setFound] = useState(false);
  const [routeTo, setRouteTo] = useState(null);

  const getRouteForStatus = useCallback(
    (status) => {
      switch (status) {
        case ORDER_STATUS_TYPES.READY_FOR_PICKUP:
          redirectTo.current = "/onmyway";
          break;
        case ORDER_STATUS_TYPES.PICKUP_STARTED:
          redirectTo.current = "/imhere";
          break;
        case ORDER_STATUS_TYPES.CUSTOMER_WAITING:
        case ORDER_STATUS_TYPES.EMPLOYEE_IN_TRANSIT:
          redirectTo.current = "/verifycode";
          break;
        case ORDER_STATUS_TYPES.COMPLETED:
          redirectTo.current = "/success";
          break;
        default:
      }

      return redirectTo.current;
    },
    [redirectTo]
  );

  const getBrandPrimary = (brand) => {
    if (!brand || brand === "null") {
      return BRANDS[`DEFAULT`].primary;
    } else {
      return BRANDS[brand].primary;
    }
  };

  useEffect(() => {
    setPending(true);
    setAppState((appState) => ({
      ...appState,
      pending: true,
    }));

    platformApi
      .getOrderSummary(id, key, brand)
      .then((order) => {
        setFound(order !== null);
        if (order) {
          setRouteTo(getRouteForStatus(order?.status));
          const primary = getBrandPrimary(
            sessionStorage.getItem("brand") || order?.brand
          );
          setAppState((appState) => ({
            ...appState,
            order: { ...order },
            pending: false,
          }));
          setThemeState((themeState) => ({ ...themeState, primary: primary }));
        }
        setPending(false);
      })
      .catch((err) => {
        setFound(null);
        setPending(false);
        setAppState((appState) => ({
          ...appState,
          hasError: true,
          pending: false,
        }));
      });
  }, [brand, getRouteForStatus, id, key, setAppState, setThemeState]);

  if (appState?.hasError) {
    return <ErrorDetails />;
  } else {
    return (
      <Container>
        {pending && (
          <>
            <Spinner />
            <Title
              text={t("loading.text.retrieving.order", {
                defaultValue: "Retrieving Order....",
              })}
            />
            <Description
              text={t("loading.text.please.wait", {
                defaultValue: "Please wait",
              })}
            />
          </>
        )}
        {!pending && !found && <Redirect to="/404" />}
        {!pending && found && <Redirect to={routeTo} />}
      </Container>
    );
  }
};
export default Loading;
