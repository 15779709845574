import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import styled from "styled-components";
import otp from "simpleotp";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import useOrderCheck from "../hooks/useOrderCheck";
import { useHistory } from "react-router-dom";

import Title from "../components/Title";
import Text from "../components/Text";
import OrderInfo from "../components/OrderInfo";
import phone from "../assets/phone.svg";

import { AppContext } from "../context/AppContext";
import { ModalContext } from "../context/ModalContext";
import { formatPhone } from "../components/Utils";
import Loader2 from "../components/Loader2";
import * as platformApi from "../handlers/platform-api";
import { ORDER_STATUS_TYPES } from "../config/OrderStatusTypes";
import ErrorDetails from "../components/ErrorDetails";
import config from "../config/vars";

dayjs.extend(duration);

const Container = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Inter, system-ui;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    color: #595D65;
  }`;

const BoxWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

const BoxHeader = styled.div`
  height: 40px;
  width: 100%;
  background: #f7f7f7;
  padding: 13px 0px 14px 16px;
  margin-top: 8px;
  font-size: 12px;
`;

const BoxBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: top;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 0px;
  background-color: #ffffff;
  font-size: 14px;
  color: #595d65;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Box = styled.div`
  width: 49%;
  flex-grow: 1;
`;

const TextBox = styled(Box)`
  padding: 14px 0px 8px 16px;
  text-align: left;
`;

const StorePhone = styled.a`
  justify-content: flex-start;
  text-decoration: none;
  color: #595d65;
`;

const IconBox = styled(Box)`
  padding: 14px 16px 8px 0px;
  text-align: right;
`;

const CodeTitle = styled.div`
  margin: 16px 0px 0px 0px;
  color: #595d65;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
`;

const CodeWrapper = styled.div`
  text-align: center;
  align-items: center;
  flex: 1;
`;

const CodeToken = styled.div`
  color: #000000;
  font-size: 64px;
  line-height: 125%;
`;

const CodeTokenText = styled.div`
  letter-spacing: 26px;
  text-indent: 26px;
`;

const CodeTimerWrapper = styled.div`
  font-family: Inter, system-ui;
  margin: 16px 0px 0px 0px;
  font-size: 10px;
  text-align: center;
`;
const CodeTimerText = styled.span`
  font-size: 12px;
  color: #595d65;
`;

const CancelText = styled.div`
  margin: 24px 0px 24px 0px;
`;

const CancelLink = styled.a`
  margin: 0px 0px 0px 0px;
  text-decoration: underline;
`;

const VerifyCode = (props) => {
  useOrderCheck();
  const { t } = useTranslation();

  const history = useHistory();
  const { value: appState, setValue: setAppState } = useContext(AppContext);
  const { setValue: setModalState } = useContext(ModalContext);
  const [code, setCode] = useState(null);
  const [seconds, setSeconds] = useState(0);

  const isCurbside = appState?.order?.type === "curbside";

  const totp = new otp.Totp({
    num_digits: 4,
    step: config.totpInterval,
  });

  const tokenOptions = {
    secret: appState?.order?.orderKey || "",
    seconds: Date.now() / 1000,
  };

  useEffect(() => {
    if (isCurbside) {
      const timer = setTimeout(() => {
        const valid = validateTotpCode();
        if (valid) {
          setSeconds((seconds) => seconds - 1);
        } else {
          setCode(null);
          _.delay(
            function () {
              setCode((code) => getTotpCode());
              setSeconds((seconds) => config.totpInterval);
            },
            750,
            ""
          );
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    if (code) {
      const orderId = appState.order.orderNumber;
      const orderKey = appState.order.orderKey;
      const brand = appState?.order?.b || sessionStorage.getItem("brand");

      platformApi
        .getOrderSummary(orderId, orderKey, brand)
        .then((order) => {
          if (
            !appState.hasError &&
            (order.status === ORDER_STATUS_TYPES.READY_FOR_PICKUP ||
              order.status === ORDER_STATUS_TYPES.COMPLETED)
          ) {
            history.go(0);
          }
        })
        .catch((err) => {
          setAppState((appState) => ({
            ...appState,
            hasError: true,
            pending: false,
          }));
        });
    }
  }, [code, appState, history, setAppState]);

  const getTotpCode = () => {
    return totp.createToken(tokenOptions);
  };

  const validateTotpCode = () => {
    const data = {
      token: code,
      ...tokenOptions,
    };
    return totp.validate(data);
  };

  const handleComeBackLaterLink = () => {
    setModalState((modalState) => ({
      ...modalState,
      showOverlay: true,
      showComeBackLater: true,
      showVehicleDetailsModal: false,
    }));
  };

  // NOTE: removed manual completion for now
  //const handlePickupComplete = () => {
  //    history.go(0);
  //}

  const storePhone = formatPhone(appState?.order?.storeInfo?.phoneNumber || "");

  const timerDuration = dayjs.duration(seconds, "seconds");
  const timerMinutes = timerDuration.minutes();
  const timerSeconds = timerDuration.seconds();
  const timerDisplay = `${String(timerMinutes).padStart(2, "0")}:${String(
    timerSeconds
  ).padStart(2, "0")}`;

  const titleText = isCurbside
    ? t("verifycode.text.curbside.header", {
        defaultValue: "An associate will be out shortly!",
      })
    : t("verifycode.text.instore.header", {
        defaultValue: "An associate will be with you shortly!",
      });
  const detailText = isCurbside
    ? t("verifycode.text.confirm.details.curbside", {
        defaultValue:
          "Please have your 4 digit code or photo ID and order # ready.  The associate will confirm your details and place your order in your car.",
      })
    : t("verifycode.text.confirm.details.instore", {
        defaultValue:
          "Please have your photo ID and order # ready.  The associate will confirm your details and retrieve your order.",
      });

  if (appState?.hasError) {
    return <ErrorDetails />;
  } else {
    return (
      <Container>
        <Title text={titleText} />
        <Text text={detailText} />
        <BoxWrapper>
          <BoxHeader>
            {t("common.text.storenumber", { defaultValue: "STORE NUMBER" })}
          </BoxHeader>
          <BoxBody>
            <TextBox>
              <StorePhone href={`tel:${storePhone}`}>{storePhone}</StorePhone>
            </TextBox>
            <IconBox>
              <a href={`tel:${storePhone}`}>
                <img src={phone} alt="" />
              </a>
            </IconBox>
          </BoxBody>
        </BoxWrapper>
        {isCurbside ? (
          <>
            {" "}
            <CodeTitle>
              {t("verifycode.text.show.code", {
                defaultValue: "SHOW CODE YO ASSOCIATE",
              })}
            </CodeTitle>
            <CodeWrapper>
              <CodeToken>
                {code === null ? (
                  <Loader2 />
                ) : (
                  <CodeTokenText>{code}</CodeTokenText>
                )}
              </CodeToken>
              <CodeTimerWrapper>
                {code === null ? null : (
                  <CodeTimerText>
                    {t("verifycode.text.code.renew", {
                      defaultValue: "Code will renew in",
                    })}
                    {`: ${timerDisplay}`}
                  </CodeTimerText>
                )}
              </CodeTimerWrapper>
            </CodeWrapper>
          </>
        ) : null}
        <OrderInfo />
        <CancelText>
          {t("verifycode.text.return.later", {
            defaultValue: "Need to return later?",
          })}
          &nbsp;
          <CancelLink onClick={handleComeBackLaterLink}>
            {t("verifycode.link.cancel.pickup", {
              defaultValue: "Let us know",
            })}
          </CancelLink>
        </CancelText>
      </Container>
    );
  }
};

export default VerifyCode;
