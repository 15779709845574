import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LinkButton from "../components/LinkButton";
import Title from "../components/Title";
import Text from "../components/Text";
import StoreInfo from "../components/StoreInfo";
import OrderInfo from "../components/OrderInfo";
import useOrderCheck from "../hooks/useOrderCheck";
import { ModalContext } from "../context/ModalContext";
import { AppContext } from "../context/AppContext";
import ErrorDetails from "../components/ErrorDetails";
import * as platformApi from "../handlers/platform-api";
import { useHistory } from "react-router-dom";
import config from "../config/vars";
import { ORDER_STATUS_TYPES } from "../config/OrderStatusTypes";

const Container = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }`;

const OnMyWay = (props) => {
  const { t } = useTranslation();
  const { value: appState, setValue: setAppState } = useContext(AppContext);
  const { setValue: setModalState } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  useOrderCheck();

  const history = useHistory();
  const brand = appState?.order?.b || sessionStorage.getItem("brand");

  const getPayload = () => {
    return {
      brand,
      order: {
        orderNumber: appState.order.orderNumber,
        orderKey: appState.order.orderKey,
        pickupInfo: appState.order.pickupInfo || {},
        shipmentKey: appState.order.shipmentKey || "not provided",
        type: appState.order.type,
      },
    };
  };

  const startPickup = function () {
    let payload = getPayload();

    setIsLoading(true);
    setModalState((modalState) => ({ ...modalState, showTapGuard: true }));

    platformApi
      .startPickup(payload)
      .then((order) => {
        setAppState((appState) => ({
          ...appState,
          brand,
          order: { ...order },
        }));
        history.push("/imhere");
      })
      .catch((e) => {
        setAppState((appState) => ({
          ...appState,
          hasError: true,
          pending: false,
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setModalState((modalState) => ({
          ...modalState,
          ...config.modalDefaults,
        }));
      });
  };

  const updatePickupInfo = function () {
    let payload = getPayload();

    setIsLoading(true);
    setModalState((modalState) => ({ ...modalState, showTapGuard: true }));

    platformApi
      .updatePickupInfo(payload)
      .then((order) => {
        setAppState((appState) => ({
          ...appState,
          brand,
          order: { ...order },
        }));
        history.push("/imhere");
      })
      .catch((e) => {
        setAppState((appState) => ({
          ...appState,
          hasError: true,
          pending: false,
        }));
      })
      .finally(() => {
        setModalState((modalState) => ({
          ...modalState,
          ...config.modalDefaults,
        }));
        setIsLoading(false);
      });
  };

  const handlePickup = () => {
    const { status, type } = appState?.order;
    if (type === "curbside") {
      setModalState((state) => ({
        ...state,
        showOverlay: true,
        showVehicleDetailsModal: true,
      }));
    } else if (status === ORDER_STATUS_TYPES.READY_FOR_PICKUP) {
      return startPickup();
    } else {
      return updatePickupInfo();
    }
  };

  const titleText = (function getTitleText() {
    switch (appState?.order?.type) {
      case "curbside":
        return t("onway.text.curbside_header", {
          defaultValue: "Your curbside order is ready!",
        });
      case "bopis":
        return t("onway.text.bopis_header", {
          defaultValue: "Your online pickup order is ready!",
        });
      default:
        return t("onway.text.generic_header", {
          defaultValue: "Your pickup order is ready!",
        });
    }
  })();

  const letUsKnowText = (function getLetUsKnowText() {
    if (appState?.order?.type === "curbside") {
      return t("onway.text.curbside_let_us_know", {
        defaultValue:
          "Please let us know when you are on your way then enter your vehicle details.",
      });
    }
    return t("onway.text.let_us_know", {
      defaultValue: "Please let us know when you are on your way.",
    });
  })();

  if (appState?.hasError) {
    return <ErrorDetails />;
  } else {
    return (
      <Container>
        <Title text={titleText} />
        <Text text={letUsKnowText} />
        <Text
          text={t("onway.text.bring_id", {
            defaultValue:
              "Reminder, please bring your ID when picking up your order.",
          })}
        />
        <StoreInfo />
        <OrderInfo />
        <LinkButton
          isLoading={isLoading}
          text={t("onway.button.primary", { defaultValue: `I'm On My Way` })}
          fn={handlePickup}
        />
      </Container>
    );
  }
};

export default OnMyWay;
