import styled from "styled-components";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { ModalContext } from "../context/ModalContext";

import {
  Sedan,
  Hatchback,
  Truck,
  Van,
  SUV,
  Convertible,
  Motorcycle,
  Other,
} from "./Form/VehicleImages";
import VehicleType from "./Form/VehicleType";
import VehicleColor from "./Form/VehicleColor";
import LinkButton from "./LinkButton";
import { AppContext } from "../context/AppContext";
import config from "../config/vars";
import { ORDER_STATUS_TYPES } from "../config/OrderStatusTypes";

import * as platformApi from "../handlers/platform-api";

const numberOfTypes = 8;
const numberOfColors = 11;

const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Inter, system-ui;
    font-style: normal;
    font-weight: bold;
    width: 100%;
  }`;

const Label = styled.div`
  font-family: Inter, system-ui;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 125%;
  color: #838895;
`;
const TypeContainer = styled.div`
  overflow-x: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ColorContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #dddcde;
  box-sizing: border-box;
  border-radius: 4px;
  height: 44px;
  margin-bottom: 24px;
  margin-top: 5px;
  padding: 10px;
  font-size: 18px;
`;

const TypeList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc((98px + 8px) * ${numberOfTypes});
  padding-bottom: 16px;
`;

const ColorList = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(65px * ${numberOfColors});
`;

const VehicleDetails = (props) => {
  const { t } = useTranslation();
  const { value: appState, setValue: setAppState } = useContext(AppContext);
  const { setValue: setModalState } = useContext(ModalContext);
  const [type, setType] = useState("");
  const [color, setColor] = useState("");
  const [make, setMake] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const brand = appState?.order?.b || sessionStorage.getItem("brand");

  const history = useHistory();

  useEffect(() => {
    setType(appState?.order?.pickupInfo?.vehicleType);
    setColor(appState?.order?.pickupInfo?.vehicleColor);
    setMake(appState?.order?.pickupInfo?.vehicleMake);
  }, [appState]);

  const types = [
    {
      name: `${t("vehicledetails.text.type.sedan", { defaultValue: "Sedan" })}`,
      image: Sedan,
    },
    {
      name: `${t("vehicledetails.text.type.hatchback", {
        defaultValue: "Hatchback",
      })}`,
      image: Hatchback,
    },
    {
      name: `${t("vehicledetails.text.type.truck", { defaultValue: "Truck" })}`,
      image: Truck,
    },
    {
      name: `${t("vehicledetails.text.type.van", { defaultValue: "Van" })}`,
      image: Van,
    },
    {
      name: `${t("vehicledetails.text.type.suv", { defaultValue: "SUV" })}`,
      image: SUV,
    },
    {
      name: `${t("vehicledetails.text.type.convertible", {
        defaultValue: "Convertible",
      })}`,
      image: Convertible,
    },
    {
      name: `${t("vehicledetails.text.type.motorcycle", {
        defaultValue: "Motorcycle",
      })}`,
      image: Motorcycle,
    },
    {
      name: `${t("vehicledetails.text.type.other", { defaultValue: "Other" })}`,
      image: Other,
    },
  ];

  const colors = [
    {
      name: `${t("vehicledetails.text.color.black", {
        defaultValue: "Black",
      })}`,
      hex: "282828",
    },
    {
      name: `${t("vehicledetails.text.color.gray", { defaultValue: "Gray" })}`,
      hex: "3D3D3D",
    },
    {
      name: `${t("vehicledetails.text.color.silver", {
        defaultValue: "Silver",
      })}`,
      hex: "A8A8A8",
    },
    {
      name: `${t("vehicledetails.text.color.white", {
        defaultValue: "White",
      })}`,
      hex: "F4F4F4",
    },
    {
      name: `${t("vehicledetails.text.color.beige", {
        defaultValue: "Beige",
      })}`,
      hex: "C5B3A3",
    },
    {
      name: `${t("vehicledetails.text.color.blue", { defaultValue: "Blue" })}`,
      hex: "436496",
    },
    {
      name: `${t("vehicledetails.text.color.yellow", {
        defaultValue: "Yellow",
      })}`,
      hex: "F9E678",
    },
    {
      name: `${t("vehicledetails.text.color.orange", {
        defaultValue: "Orange",
      })}`,
      hex: "FFA91F",
    },
    {
      name: `${t("vehicledetails.text.color.red", { defaultValue: "Red" })}`,
      hex: "BC342E",
    },
    {
      name: `${t("vehicledetails.text.color.green", {
        defaultValue: "Green",
      })}`,
      hex: "2B8941",
    },
    {
      name: `${t("vehicledetails.text.color.purple", {
        defaultValue: "Purple",
      })}`,
      hex: "542A54",
    },
  ];

  const getPayload = () => {
    return {
      brand,
      order: {
        orderNumber: appState.order.orderNumber,
        orderKey: appState.order.orderKey,
        shipmentKey: appState.order.shipmentKey || "not provided",
        pickupInfo: {
          vehicleColor: color,
          vehicleType: type,
          vehicleMake: make,
        },
        type: appState.order.type || "curbside",
      },
    };
  };

  const startPickup = function () {
    let payload = getPayload();

    setIsLoading(true);
    setModalState((modalState) => ({ ...modalState, showTapGuard: true }));

    platformApi
      .startPickup(payload)
      .then((order) => {
        setAppState((appState) => ({
          ...appState,
          brand,
          order: { ...order },
        }));
        history.push("/imhere");
      })
      .catch((e) => {
        setAppState((appState) => ({
          ...appState,
          hasError: true,
          pending: false,
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setModalState((modalState) => ({
          ...modalState,
          ...config.modalDefaults,
        }));
      });
  };

  const updatePickupInfo = function () {
    let payload = getPayload();

    setIsLoading(true);
    setModalState((modalState) => ({ ...modalState, showTapGuard: true }));

    platformApi
      .updatePickupInfo(payload)
      .then((order) => {
        setAppState((appState) => ({
          ...appState,
          brand,
          order: { ...order },
        }));
        history.push("/imhere");
      })
      .catch((e) => {
        setAppState((appState) => ({
          ...appState,
          hasError: true,
          pending: false,
        }));
      })
      .finally(() => {
        setModalState((modalState) => ({
          ...modalState,
          ...config.modalDefaults,
        }));
        setIsLoading(false);
      });
  };

  const handlePickup = () => {
    if (isEmpty(type) || isEmpty(color)) {
      setModalState((state) => ({
        ...state,
        showOverlay: true,
        showVehicleDetailsModal: false,
        showMissingDetails: true,
      }));
    } else {
      const status = appState?.order?.status;
      if (status === ORDER_STATUS_TYPES.READY_FOR_PICKUP) {
        return startPickup();
      } else {
        return updatePickupInfo();
      }
    }
  };

  const handleMake = (e) => {
    setMake(e.target.value);
  };

  return (
    <Container>
      <Label>{t("vehicledetails.text.type", { defaultValue: "Type" })}</Label>
      <TypeContainer>
        <TypeList>
          {types.map((element, i) => (
            <VehicleType
              key={i}
              name={element.name}
              image={
                <element.image
                  hex={element.name === type ? "#000000" : "#838895"}
                />
              }
              selected={element.name === type ? true : false}
              fn={setType}
            />
          ))}
        </TypeList>
      </TypeContainer>

      {/*  Vehicle color */}
      <Label>{t("vehicledetails.text.color", { defaultValue: "Color" })}</Label>
      <ColorContainer>
        <ColorList>
          {colors.map((element, i) => (
            <VehicleColor
              key={i}
              name={element.name}
              hex={element.hex}
              selected={element.name === color ? true : false}
              fn={setColor}
            />
          ))}
        </ColorList>
      </ColorContainer>
      <Label>
        {t("vehicledetails.text.makemodel", {
          defaultValue: "Make/Model (optional)",
        })}
      </Label>
      <Input defaultValue={make} onChange={handleMake}></Input>
      <LinkButton
        text={t("vehicledetails.button.primary", { defaultValue: "Done" })}
        isLoading={isLoading}
        fn={handlePickup}
      />
    </Container>
  );
};

export default VehicleDetails;
