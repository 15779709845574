import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import config from "../config/vars";
import { formatPhone } from "../components/Utils.js";

import styled from "styled-components";
import LinkButton from "../components/LinkButton";
import Title from "../components/Title";
import Text from "../components/Text";
import OrderInfo from "../components/OrderInfo";
import phone from "../assets/phone.svg";
import pencil from "../assets/pencil.svg";

import { ModalContext } from "../context/ModalContext";
import { AppContext } from "../context/AppContext";
import { useHistory } from "react-router-dom";
import useOrderCheck from "../hooks/useOrderCheck";

import * as platformApi from "../handlers/platform-api";
import ErrorDetails from "../components/ErrorDetails";

const Container = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Inter, system-ui;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    color: #595D65;
  }`;

const BoxWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 16px;
`;

const BoxHeader = styled.div`
  height: 40px;
  width: 100%;
  background: #f7f7f7;
  padding: 13px 0px 14px 16px;
  margin-top: 8px;
  font-size: 12px;
`;

const BoxBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: top;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 0px;
  background-color: #ffffff;
  font-size: 14px;
  color: #595d65;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Box = styled.div`
  width: 49%;
  flex-grow: 1;
`;

const TextBox = styled(Box)`
  padding: 14px 0px 8px 16px;
  text-align: left;
  width: 65%;
`;

const StorePhone = styled.a`
  justify-content: flex-start;
  text-decoration: none;
  color: #595d65;
`;

const IconBox = styled(Box)`
  padding: 14px 16px 8px 0px;
  text-align: right;
  width: 34%;
`;
const CancelText = styled.div`
  margin: 10px 0px 10px 0px;
`;
const CancelLink = styled.a`
  margin: 0px 0px 0px 0px;
  text-decoration: underline;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

const ImHere = (props) => {
  useOrderCheck();
  const { t } = useTranslation();
  const { value: appState, setValue: setAppState } = useContext(AppContext);
  const { setValue: setModalState } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  const brand = appState?.order?.b || sessionStorage.getItem("brand");

  const history = useHistory();

  console.log({ appState });

  const handleEditVehicleInfo = () => {
    setModalState((state) => ({
      ...state,
      showOverlay: true,
      showVehicleDetailsModal: true,
    }));
  };

  const handleArrivalClick = () => {
    let payload = {
      brand,
      order: {
        orderNumber: appState?.order?.orderNumber || "",
        orderKey: appState?.order?.orderKey || "",
        shipmentKey: appState?.order?.shipmentKey || "not provided",
        type: appState?.order?.type || "",
      },
    };

    setIsLoading(true);
    setModalState((modalState) => ({ ...modalState, showTapGuard: true }));
    platformApi
      .customerArrived(payload)
      .then((order) => {
        setAppState((appState) => ({
          ...appState,
          order: { ...order },
        }));
        history.push("/verifycode");
      })
      .catch((err) => {
        setIsLoading(false);
        setAppState((appState) => ({
          ...appState,
          hasError: true,
          pending: false,
        }));
      })
      .finally(() => {
        setModalState((modalState) => ({
          ...modalState,
          ...config.modalDefaults,
        }));
      });
  };

  const handleComeBackLaterLink = () => {
    setModalState((modalState) => ({
      ...modalState,
      showOverlay: true,
      showComeBackLater: true,
      showVehicleDetailsModal: false,
    }));
  };

  const getPickupInfo = () => {
    const vehicleColor = appState?.order?.pickupInfo?.vehicleColor || "";
    const vehicleType = appState?.order?.pickupInfo?.vehicleType || "";
    const vehicleMake = appState?.order?.pickupInfo?.vehicleMake || "";
    const makeInfo = !isEmpty(vehicleMake) ? `(${vehicleMake})` : "";
    const vehicleDetails = `${vehicleColor} ${vehicleType} ${makeInfo}`;
    console.log({ appState });
    return vehicleDetails;
  };

  const storePhone = formatPhone(appState?.order?.storeInfo?.phoneNumber || "");
  const isCurbside = appState?.order?.type === "curbside";
  const detailText = isCurbside
    ? t("imhere.text.curbside.location", {
        defaultValue:
          "Our curbside pickup is usually located near the store entrance.",
      })
    : t("imhere.text.bopis", {
        defaultValue: `Tap "I'm Here" when you're heading inside and an associate will assist you with picking up your order.`,
      });

  if (appState?.hasError) {
    return <ErrorDetails />;
  } else {
    return (
      <Container>
        <Title
          text={t("imhere.text.header", {
            defaultValue: "We can't wait to see you!",
          })}
        />
        <Text text={detailText} />
        <BoxWrapper>
          <BoxHeader>
            {t("common.text.storenumber", { defaultValue: "STORE NUMBER" })}
          </BoxHeader>
          <BoxBody>
            <TextBox>
              <StorePhone href={`tel:${storePhone}`}>{storePhone}</StorePhone>
            </TextBox>
            <IconBox>
              <a href={`tel:${storePhone}`}>
                <img src={phone} alt="" />
              </a>
            </IconBox>
          </BoxBody>
        </BoxWrapper>
        {isCurbside && (
          <BoxWrapper onClick={handleEditVehicleInfo}>
            <BoxHeader>
              {t("imhere.text.boxheader.vehicleinfo", {
                defaultValue: "VEHICLE INFORMATION",
              })}
            </BoxHeader>
            <BoxBody>
              <TextBox>{getPickupInfo()}</TextBox>
              <IconBox>
                <img src={pencil} alt="" />
              </IconBox>
            </BoxBody>
          </BoxWrapper>
        )}
        <OrderInfo />
        <CancelText>
          {t("imhere.text.cancel.pickup", {
            defaultValue: "Need to cancel your arrival?",
          })}
          <CancelLink onClick={handleComeBackLaterLink}>
            &nbsp;
            {t("imhere.link.cancel.pickup", { defaultValue: "Let us know" })}
          </CancelLink>
        </CancelText>
        <ButtonWrapper>
          <LinkButton
            text={t("imhere.button.primary", { defaultValue: "I'm Here" })}
            isLoading={isLoading}
            fn={handleArrivalClick}
          />
        </ButtonWrapper>
      </Container>
    );
  }
};

export default ImHere;
